import { css, ThemeProvider as EmotionThemeProvider } from '@emotion/react'
import { ReactLenis } from '@studio-freight/react-lenis'
import React, { memo } from 'react'

import { MediaContextProvider, mediaStyle } from './media'

export type Theme = typeof theme

export const theme = {
  colors: {
    variants: {
      neutralLight5: '#FFFFFF',
      neutralLight4: '#F9FAF8',
      neutralLight3: '#F4F5F3',
      neutralLight2: '#E9EBE8',
      neutralLight1: '#BDBDBD',
      neutralDark3: '#5C5D5C',
      neutralDark2: '#1B1C1B',
      neutralDark1: '#000000',
      primaryDark: '#6C756E',
      primaryLight: '#828E85',
      danger: '#F23030',
    },
  },
  fontFamily: {
    heading: "'linotype-didot', serif",
    label: "'adobe-caslon-pro', serif",
    paragraph: "'acumin-pro', sans-serif",
  },
}

export type Styles = typeof styles

const titleStyle = css`
  color: ${theme.colors.variants.neutralDark2};
  font-family: ${theme.fontFamily.heading};
  text-transform: uppercase;
`

const descriptionStyle = css`
  color: ${theme.colors.variants.neutralDark3};
  font-family: ${theme.fontFamily.label};
  font-weight: 400;
`

export const styles = {
  label: {
    default: css`
      color: ${theme.colors.variants.neutralDark2};
      font-family: ${theme.fontFamily.label};
      font-size: 1.125rem;
      font-style: italic;
      line-height: 1.3125rem;
      margin: 0 auto 2.25rem;

      @media (max-width: 1199px) {
        margin-bottom: 1.125rem;
      }
    `,
    custom: css`
      color: ${theme.colors.variants.neutralDark2};
      font-family: ${theme.fontFamily.paragraph};
      font-size: 0.625rem;
      letter-spacing: 0.2em;
      line-height: 0.875rem;
      text-transform: uppercase;
    `,
  },
  title: {
    xl: css`
      ${titleStyle}
      font-size: 5.25rem;
      line-height: 5.25rem;

      @media (max-width: 1199px) {
        font-size: 3rem;
        line-height: 3rem;
      }
    `,
    large: css`
      ${titleStyle}
      font-size: 3.75rem;
      line-height: 3.75rem;
    `,
    medium: css`
      ${titleStyle}
      font-size: 3rem;
      line-height: 3rem;

      @media (max-width: 1199px) {
        font-size: 1.875rem;
        line-height: 1.875rem;
      }
    `,
    xm: css`
      ${titleStyle}
      font-size: 2.25rem;
      line-height: 2.25rem;

      @media (max-width: 1199px) {
        font-size: 1.875rem;
        line-height: 1.875rem;
      }
    `,
    small: css`
      ${titleStyle}
      font-size: 1.5rem;
      line-height: 1.8125rem;
    `,
  },
  description: {
    xl: css`
      ${descriptionStyle}
      color: ${theme.colors.variants.neutralDark2};
      font-size: 1.5rem;
      font-style: italic;
      line-height: 1.875rem;
    `,
    large: css`
      ${descriptionStyle}
      font-size: 1.125rem;
      line-height: 1.625rem;
    `,
    small: css`
      ${descriptionStyle}
      font-size: 1rem;
      line-height: 1.375rem;
    `,
  },
  img: css`
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    will-change: contents;
  `,
}

interface Props {
  children: any
  pageContext: any
}

export const ThemeProvider = memo(function ThemeProvider({ children }: Props) {
  const isBrowser = typeof window !== 'undefined'

  let smoothScroll = false
  if (isBrowser) smoothScroll = window.innerWidth > 1199 ? true : false

  return (
    <EmotionThemeProvider theme={theme}>
      <style type="text/css">{mediaStyle}</style>
      <MediaContextProvider>
        {smoothScroll ? (
          <ReactLenis root options={{ duration: 1.4 }}>
            {children}
          </ReactLenis>
        ) : (
          children
        )}
      </MediaContextProvider>
    </EmotionThemeProvider>
  )
})
